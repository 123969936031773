<template>
  <div class="commissionDetails_page">
    <div class="tabTitle">
      <div class="tip">注：我的订单指通过我分享的商品链接购买的订单，团队订单指通过我团队下合伙人分享的商品链接购买的订单</div>
      <div class="top-desc-box">
        <div class="title-time">
          <div class="title">
            <span class="popover-tip">
              <span v-if="urlData.type == 'com'">佣金总览</span>
              <span v-if="urlData.type == 'order'">订单概览</span>
            </span>
          </div>
          <div class="select-date">
            <van-field v-model="startTime" readonly name="time" label="" placeholder="开始时间" input-align="center"
              @click="startTimeShow = true" />
            <span class="to">至</span>
            <van-field v-model="endTime" readonly name="time" label="" placeholder="结束时间" input-align="center"
              @click="endTimeShow = true" />
          </div>
        </div>
        <ul v-if="urlData.type == 'com'">
          <li>
            <p>{{ amountText(userCommissionAmountSummery.totalCommissionAmount || 0) }}<span
                v-if="userCommissionAmountSummery.totalCommissionAmount >= 10000">万</span></p>
            <p>总收益(元)</p>
          </li>
          <li>
            <p>{{ amountText(userCommissionAmountSummery.oneCommissionAmount || 0) }}<span
                v-if="userCommissionAmountSummery.oneCommissionAmount >= 10000">万</span></p>
            <p>推广费(元)</p>
          </li>
          <li>
            <p>{{ amountText(userCommissionAmountSummery.twoCommissionAmount || 0) }}<span
                v-if="userCommissionAmountSummery.twoCommissionAmount >= 10000">万</span></p>
            <p>奖金(元)</p>
          </li>
        </ul>
        <ul v-if="urlData.type == 'order'">
          <li>
            <p>{{ amountText(userCommissionCountSummery.totalCommissionCount || 0) }}<span
                v-if="userCommissionCountSummery.totalCommissionCount >= 10000">万</span></p>
            <p>总订单</p>
          </li>
          <li>
            <p>{{ amountText(userCommissionCountSummery.returnCommissionCount || 0) }}<span
                v-if="userCommissionCountSummery.returnCommissionCount >= 10000">万</span></p>
            <p>退单数</p>
          </li>
          <li>
            <p>{{ amountText(userCommissionCountSummery.oneCommissionCount || 0) }}<span
                v-if="userCommissionCountSummery.oneCommissionCount >= 10000">万</span></p>
            <p>我的订单</p>
          </li>
          <li>
            <p>{{ amountText(userCommissionCountSummery.twoCommissionCount || 0) }}<span
                v-if="userCommissionCountSummery.twoCommissionCount >= 10000">万</span></p>
            <p>团队订单</p>
          </li>
        </ul>
      </div>
      <van-tabs v-model="active" swipeable animated sticky color="#FF006E" title-inactive-color="#777" title-active-color="#000"
        @change="tabChange">
        <van-tab v-for="(item, index) in tabTitle" :title="item.title" :name="item.index" :key="index">
          <van-loading v-if="tabLoading" type="spinner" color="#FF006E" vertical>加载中...</van-loading>
          <div class="commission_list" v-if="!tabLoading">
            <van-list v-model="moreLoading" :finished="finished" offset="50" finished-text="没有更多了" @load="upMoreLoad"
              v-if="commissionList && commissionList.length > 0">
              <div class="item_box" v-for="(item, index) in commissionList" :key="index">
                <div class="order_type"
                  :class="{ 'btn_color1': item.commissionType == 1, 'btn_color2': item.commissionType == 2, }">
                  {{ commissionTypeText(item, 'order_type') }}</div>
                <div class="product">
                  <div class="logo_box">
                    <img :src="item.productUrl" alt="">
                  </div>
                  <div class="details">
                    <b>{{ item.producctName }}</b>
                    <p>支付金额：¥{{ item.orderAmount }}</p>
                    <p>{{ item.orderTime }}</p>
                    <p>订单状态：{{ item.orderStatus == 'ORDER_PAY_SUCCESS' ? '成功' : item.orderStatus == 'REFUND_DELIVERY' ? '退款' : '' }}</p>
                  </div>
                </div>
                <div class="order_info">
                  <div class="left">
                    <span class="order_no">订单编号：{{ item.orderNo }}<span class="copy-btn"
                        @click="handleCopy(item)">复制</span></span>
                    <div class="money-status">
                      <span class="money">{{ orderStatusText(item) }}：<span
                          :class="{ 'color_class1': item.orderStatus == 'ORDER_PAY_SUCCESS', 'color_class2': item.orderStatus == 'REFUND_DELIVERY' }">¥{{ item.commissionAmount }}</span><span>{{ commissionTypeText(item, 'commission_type') }}</span></span>
                      <span class="right" style="color: #FF006E;" v-if="item.commissionStatus == 1">待结算</span>
                      <span class="right" v-if="item.commissionStatus == 2">已结算</span>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
            <div class="noData" v-if="commissionList.length === 0"></div>
          </div>
        </van-tab>
      </van-tabs>
      <van-popup v-model="startTimeShow" :closeable="false" :round="true" :close-on-click-overlay="true" overlay
        position="bottom">
        <div class="clearTime" @click="handleClearStartDate">清空时间</div>
        <van-datetime-picker v-model="currentStartDate" type="date" title="开始时间选择年月日" :min-date="minDate"
          :max-date="maxDate" :formatter="formatter" @confirm="handleConfirmStart" @cancel="startTimeShow = false" />
      </van-popup>
      <van-popup v-model="endTimeShow" :closeable="false" :round="true" :close-on-click-overlay="true" overlay
        position="bottom">
        <div class="clearTime" @click="handleClearEndDate">清空时间</div>
        <van-datetime-picker v-model="currentEndDate" type="date" title="结束时间选择年月日" :min-date="minDate"
          :max-date="maxDate" :formatter="formatter" @confirm="handleConfirmEnd" @cancel="endTimeShow = false" />
      </van-popup>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import {
  getToken,
  urlParse,
} from '@/utils/tokenUtils'
import './index.less'
import {
  GetUserCommissionPageList,
  GetUserCommissionAmountSummery,
  GetUserCommissionCountSummery
} from '@/service/withdraw'
export default {
  data() {
    return {
      mid: getToken('mid'),
      mgUuid: getToken('mgUuid'),
      urlData: {},
      actions: [],
      startTime: '',
      endTime: '',
      minDate: new Date('2022-01-01'),
      maxDate: new Date('2025-12-31'),
      currentStartDate: new Date(),
      currentEndDate: new Date(),
      startTimeShow: false,
      endTimeShow: false,

      userCommissionAmountSummery: {}, // 佣金金额汇总
      userCommissionCountSummery: {}, // 订单数汇总
      tabTitle: [
        { title: '待结算', index: 1 },
        { title: '已结算', index: 2 }
      ],
      active: 1,
      tabLoading: false,
      moreLoading: false,
      finished: false,
      commissionList: [], // 奖励明细列表数据
      pageCurrent: 1,
      pageSize: 10
    }
  },
  computed: {
    // 当超过 10000 时，截取后用万单位来显示
    amountText() {
      return (value) => {
        if (value < 10000) {
          return value
        } else {
          return Math.floor((value / 10000) * 100) / 100
        }
      }
    },
    orderStatusText() {
      return (item) => {
        // console.log('item', item)
        if (item.orderStatus == 'ORDER_PAY_SUCCESS') return '我的收益'
        if (item.orderStatus == 'REFUND_DELIVERY') return '退单扣佣'
      }
    },
    commissionTypeText() {
      return (item, t) => {
        // console.log('item', item)
        if (t == 'order_type') {
          if (item.commissionType == 1) return '我的订单'
          if (item.commissionType == 2) return '团队订单'
        }
        if (t == 'commission_type') {
          if (item.commissionType == 1) return '（推广费）'
          if (item.commissionType == 2) return '（奖金）'
        }
      }
    }
  },
  created() {
    const urlData = urlParse(window.location.href)
    this.urlData = urlParse(window.location.href)
    // console.log(urlData, 'urlData')
    if (urlData.type == 'com') {
      document.title = '奖励明细'
      this.actions = [{ text: '注：佣金概览各指标不含退单' }]
    }
    if (urlData.type == 'order') {
      document.title = '分佣订单'
      this.actions = [{ text: '注：总订单含退单，我的订单、团队订单不含退单' }]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    // 选择开始时间，点击确认
    async handleConfirmStart(value) {
      // console.log('value：', value)
      this.startTime = moment(value).format('YYYY-MM-DD')
      // console.log('startTime------', this.startTime)
      this.startTimeShow = false
      if (this.startTime && this.endTime && this.startTime > this.endTime) {
        this.$toast('开始时间不能大于结束时间')
      } else {
        this.pageCurrent = 1
        await this.init()
      }
    },
    // 选择结束时间，点击确认
    async handleConfirmEnd(value) {
      // console.log('value：', value)
      this.endTime = moment(value).format('YYYY-MM-DD')
      // console.log('endTime------', this.endTime)
      this.endTimeShow = false
      if (this.startTime && this.endTime && this.startTime > this.endTime) {
        this.$toast('开始时间不能大于结束时间')
      } else {
        this.pageCurrent = 1
        await this.init()
      }
    },
    handleClearStartDate() {
      this.startTimeShow = false
      this.startTime = ''
      this.pageCurrent = 1
      this.init()
    },
    handleClearEndDate() {
      this.endTimeShow = false
      this.endTime = ''
      this.pageCurrent = 1
      this.init()
    },
    // handleCancelStart() {
    //   this.startTimeShow = false
    // },
    // handleCancelEnd() {
    //   this.endTimeShow = false
    // },

    init() {
      if (this.urlData.type == 'com') {
        this.getUserCommissionAmountSummery()
      }
      if (this.urlData.type == 'order') {
        this.getUserCommissionCountSummery()
      }
      this.upMoreLoad()
    },
    // 佣金金额汇总
    getUserCommissionAmountSummery() {
      let params = {
        startTime: this.startTime ? this.startTime + ' 00:00:00' : '',
        endTime: this.endTime ? this.endTime + ' 23:59:59' : ''
      }
      GetUserCommissionAmountSummery(params).then((res) => {
        const { code, data, message } = res
        if (code === '0') {
          this.userCommissionAmountSummery = data || {}
          // this.userCommissionAmountSummery.totalCommissionAmount = 30101 // 测试
        } else {
          this.$toast(message)
        }
      })
    },
    // 订单数汇总
    getUserCommissionCountSummery() {
      let params = {
        startTime: this.startTime ? this.startTime + ' 00:00:00' : '',
        endTime: this.endTime ? this.endTime + ' 23:59:59' : ''
      }
      GetUserCommissionCountSummery(params).then((res) => {
        const { code, data, message } = res
        if (code === '0') {
          this.userCommissionCountSummery = data || {}
          // this.userCommissionCountSummery.twoCommissionCount = 30101 // 测试
        } else {
          this.$toast(message)
        }
      })
    },
    // 奖励明细数据
    getUserCommissionPageList() {
      const idx = this.pageCurrent
      let params = {
        commissionStatus: this.active,
        pageIndex: this.pageCurrent,
        pageSize: this.pageSize,
        startTime: this.startTime ? this.startTime + ' 00:00:00' : '',
        endTime: this.endTime ? this.endTime + ' 23:59:59' : ''
      }
      GetUserCommissionPageList(params).then((res) => {
        const { code, data, message } = res
        if (code === '0') {
          this.tabLoading = false
          this.moreLoading = false
          if (idx > 1) {
            this.commissionList = [...this.commissionList, ...data.list]
          } else {
            this.commissionList = data.list
          }
          if (data && this.commissionList.length == data.total) {
            this.finished = true
          } else {
            this.finished = false
          }
          // console.log('this.commissionList', this.commissionList)
        } else {
          this.tabLoading = false
          this.$toast(message)
        }
      })
    },
    // 上滑加载更多
    upMoreLoad() {
      this.moreLoading = true
      this.getUserCommissionPageList()
      this.pageCurrent += 1
    },
    // 标签切换change
    tabChange() {
      this.commissionList = []
      this.pageCurrent = 1
      this.tabLoading = true
      this.moreLoading = true
      this.upMoreLoad()
    },
    // 复制订单编号
    handleCopy(item) {
      let orderNo = item.orderNo // 拿到想要复制的值
      let copyInput = document.createElement('input') // 创建input元素
      document.body.appendChild(copyInput) // 向页面底部追加输入框
      copyInput.setAttribute('value', orderNo) // 添加属性，将orderNo赋值给input元素的value属性
      copyInput.select() // 选择input元素
      document.execCommand('Copy') // 执行复制命令
      this.$toast('订单编号已复制~') // 弹出提示信息
      // 复制之后再删除元素，否则无法成功赋值
      copyInput.remove() // 删除动态创建的节点
    }
  }
}
</script>
<style lang="less" scoped></style>